.traffic-box{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 100px;
}
.traffic-item{
  width: 100px;
  height: 100px;
}

.traffic-item-twinkle{
  animation: twinkle 0.6s infinite;
}
@keyframes twinkle{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}