.ocean{
  height :100%;
  width : 100%;
  overflow: hidden;
 
}
.loading{
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 99;
  background: rgba(46, 66, 77, .8)
  filter drop-shadow(0px 1px 1px rgba(0, 0, 0, .25))
  backdrop-filter blur(10px);
  display: flex;
  justify-content: space-around;
  align-items: center
}
  
  .progress{
    font-size: 3.6rem;
    color :#FFFFFF;
    text-shadow :0 1px 0 hsl(174,5%,80%),
                0 2px 0 hsl(174,5%,75%),
                0 3px 0 hsl(174,5%,70%),
                0 4px 0 hsl(174,5%,66%),
                0 5px 0 hsl(174,5%,64%),
                0 6px 0 hsl(174,5%,62%),
                0 7px 0 hsl(174,5%,61%),
                0 8px 0 hsl(174,5%,60%),
                0 0 5px rgba(0,0,0,.05),
              0 1px 3px rgba(0,0,0,.2),
              0 3px 5px rgba(0,0,0,.2),
              0 5px 10px rgba(0,0,0,.2),
            0 10px 10px rgba(0,0,0,.2),
            0 20px 20px rgba(0,0,0,.3)
  }
  .point{
    position: fixed;
    top: 50%;
    left: 50%;
    z-index :10
  }
  .label{
    position: absolute;
    top: -16px;
    left: -16px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #00000077;
    border: 1px solid #ffffff77;
    color: #ffffff;
    font-family: Helvetica, Arial, sans-serif;
    text-align: center;
    line-height: 32px;
    font-weight: 100;
    font-size: 14px;
    cursor: help;
    transform: scale(0, 0);
    transition: transform 0.3s;
  }
  .text{
    position: absolute;
    top: 30px;
    left: -120px;
    width: 200px;
    padding: 20px;
    border-radius: 4px;
    background: rgba(0, 0, 0, .6);
    border: 1px solid #ffffff77;
    color: #ffffff;
    line-height: 1.3em;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 100;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
    text-align: justify;
    text-align-last: left;
  }
  .text :hover{
    opacity: 1;

  }
  .text .visible .label{
    transform: scale(1, 1);
  }